import request from '@/service/request'
import { getXMenuType } from '@/utils/constant'

export function list(params) {
  return request({
    url: '/api/prim_prod/prim_struct/',
    method: 'get',
    params
  })
}

export function getListByProtoId(data, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/productPrototype/getByIdForShow',
    method: 'post',
    data
  })
}

export function batchUpdateStyle(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/productPrototype/batchUpdateStyle',
    method: 'post',
    data
  })
}

export function pictureUploaded(data) {
  return request({
    url: '/product/oss',
    method: 'post',
    data
  })
}

export function add(data) {
  return request({
    url: '/api/prim_prod/prim_struct/',
    method: 'post',
    data
  })
}

export function read(data) {
  return request({
    url: `/api/prim_prod/prim_struct/${data}/`,
    method: 'get',
    data
  })
}

export function edit(id, data) {
  return request({
    url: '/api/prim_prod/prim_struct/' + id + '/',
    method: 'patch',
    data
  })
}

export function del(id) {
  return request({
    url: '/api/prim_prod/prim_struct/' + id + '/',
    method: 'delete'
  })
}
