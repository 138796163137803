<template>
  <BaseDialog :dialogVisible.sync="dialog" width="1000px" :title="title">
    <div v-if="dialog" v-loading="loading">
      <el-tabs class="structFormComponent" type="border-card" v-model="activeName" @tab-remove="remove">
        <el-tab-pane
          :closable="!disabled(form)"
          :label="form.name || '暂无款式'"
          :name="form.id | paneName"
          :key="form.id | paneName"
          v-for="(form, index) in data.styleList"
        >
          <span slot="label">
            {{ form.name || '暂无款式' }}
            <color-text-btn v-if="disabled(form)" type="danger">(已上架) </color-text-btn>
          </span>
          <template>
            <BaseForm
              ref="form"
              label-width="90px"
              :isDisable="disabled(form)"
              :cols="formField"
              :form="form"
              :formVisible="dialog"
            >
              <template #prim_colorSlot="{ scoped: { prop } }">
                <!-- <el-color-picker v-model="form[prop]"></el-color-picker> -->
                <ColorSelect :color.sync="form[prop]" :sup_this="sup_this" />
              </template>
              <template #figuresSlot="{ scoped: { prop } }">
                <ImgUpload :hideDialog="disabled(form)" :disabled="disabled(form)" :files.sync="form[prop]" multiple :limit="5" />
              </template>
              <!-- <template #detailSlot="{ scoped: { prop } }">
                <Editor
                  :id="`structEditor_${index}`"
                  :val.sync="form[prop]"
                  placeholder="请输入款式详情"
                  :height="200"
                  serverUrl="serverUrl"
                  useObjectStr="CONTENT_UEDITOR_FILE"
                />
              </template> -->
            </BaseForm>
          </template>
        </el-tab-pane>

        <el-tab-pane name="addNewTab" disabled>
          <span slot="label">
            <el-button @click="addNewStruct" icon="el-icon-plus" size="mini" type="primary"> 添加款式 </el-button></span
          >
        </el-tab-pane>
      </el-tabs>
    </div>
    <template #footer>
      <el-button :loading="loading" type="primary" @click="doSubmit"> 确认 </el-button>
      <LoadingBtn @click="cancel"> 取消 </LoadingBtn>
    </template>
  </BaseDialog>
</template>
<script>
import ImgUpload from '@/components/fileUpload/imgUpload'
import ColorSelect from '@/components/colorSelect'

import cloneDeep from 'lodash/cloneDeep'
import { structField as formField } from '../../field'
import { commonFromMixin } from '@/mixins'
import { createRandomNum, file2Base64, handleFigurePaths, HasPermission } from '@/utils'
import { STRUCR_ITEM, CUSTOM, SHELVES } from '@/utils/constant'
import { edit, del, add, batchUpdateStyle } from '@/api/product/protoStructApi'
import { deepClone } from '@/components/avue/utils/util'
export default {
  mixins: [commonFromMixin],
  components: {
    ImgUpload,
    ColorSelect
    // Editor,
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      formField,
      activeName: '',
      oStructs: [],
      loading: false
    }
  },
  filters: {
    paneName(id) {
      return id && id.toString()
    }
  },
  computed: {
    disabled() {
      return (data) => {
        return data.status == SHELVES
      }
    }
  },
  methods: {
    HasPermission,
    existSameSizeName() {
      const styleList = this.data.styleList
      const set = [...new Set(styleList.map(({ name }) => name.toUpperCase()))]
      return set.length < styleList.length
    },
    remove(name) {
      const styleList = this.data.styleList
      if (styleList.length <= 1) {
        return this.$message.warning('尺码至少需要存在一个！')
      }
      const nameIndex = this.data.styleList.findIndex(({ id }) => id == name)
      if (nameIndex >= 0) {
        const sIndex = Math.max(0, nameIndex - 1)
        this.$delete(styleList, nameIndex)
        this.activeName = styleList[sIndex].id.toString()
      }
    },

    addNewStruct() {
      const tmpObj = {
        ...STRUCR_ITEM,
        id: `${CUSTOM}-${createRandomNum()}`,
        prototypeId: this.data.id
      }

      this.data.styleList.push(tmpObj)
      this.activeName = tmpObj.id
    },
    isCustomId(id) {
      return id.toString().indexOf(CUSTOM) >= 0
    },
    resetForm() {
      this.$nextTick(() => {
        this.dialog = false
      })
    },
    linkToCreateCoordPage(addStructIds) {
      try {
        const { base_sizes, styleList = [] } = this.data || {}
        const existCoord = styleList.some(({ prim_show_groups }) => prim_show_groups && prim_show_groups.length)
        if (!existCoord || !base_sizes || base_sizes.length == 0) {
          return
        }
        let structIds = styleList
          .map(({ id }) => id)
          .filter((id) => {
            return (id + '').indexOf(CUSTOM) == -1
          })
        structIds = structIds.concat(addStructIds)
        const id = base_sizes[0] && base_sizes[0].id
        this.$confirm('新增款式之后需要重新生成坐标图, 是否跳转到坐标图页面?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.$router.push(
              `/product/maintain/createCoordPic?id=${id}&&structId=${structIds}&&protoId=${this.data.id}`
            )
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消跳转'
            })
          })
      } catch (err) {}

      // console.log('this.data', this.data)
    },
    async validate() {
      const { form } = this.$refs
      const forms = Array.isArray(form) ? form : [form]
      const successData = await Promise.all(
        forms.map(async (node) => {
          try {
            return await node.validate()
          } catch (err) {
            return false
          }
        })
      )
      return successData
    },
    async doSubmit() {
      const validData = await this.validate()
      const validIndex = validData.findIndex((bool) => {
        return bool == false
      })
      if (validIndex >= 0) {
        this.activeName = this.data.styleList[validIndex].id.toString()
        return
      }
      if (this.existSameSizeName()) {
        return this.$message.warning('存在两个一样的款式，款式编码必须唯一。')
      }
      this.doFunc()
    },
    async doFunc() {
      this.loading = true
      const { data: { id, styleList: allStyleList }, oStructs } = this
      let params = {}
      const styleList = allStyleList
        .filter(({ status }) => status != SHELVES)
        .map(style => {
          return {
            ...style,
            displayImageUrl: undefined,
            id: this.isCustomId(style.id) ? undefined : style.id
          }
        })
      const oStructsObj = oStructs.reduce((prev, next) => {
        prev[next.id] = next
        return prev
      }, {})
      const styleIdList = styleList.map(({ id }) => id)
      const oStructIds = oStructs.map(({ id }) => id)
      params.styleDelIdList = oStructIds.filter(id => {
        return !styleIdList.includes(id)
      })
      params.styleDTOList = await this.doFiguresFunc(styleList, oStructsObj)
      let res = await awaitResolve(batchUpdateStyle(params))
      if (res) {
        let addFuncArr = styleList.filter(({ id }) => !id)
        if (addFuncArr.length) {
          console.log('addStructIds', addFuncArr)
          // this.linkToCreateCoordPage(addStructIds)
        }
        this.success()
      }
      this.loading = false
    },

    doDel(styleList) {
      const delArr = this.oStructs.filter(({ id }) => {
        return styleList.every((item) => item.id != id)
      })
      return delArr.map(({ id }) => del(id))
    },

    doAdd(id, styleList) {
      const addArr = styleList.filter(({ id }) => {
        if (!id) return true
        return this.isCustomId(id)
      })
      return addArr.map(async (form) => {
        const copyFrom = cloneDeep(form)
        const pArr = copyFrom.figures.map(async (row) => {
          return await file2Base64(row)
        })
        const res = await Promise.all(pArr)
        const figures = res.map((base64, index) => {
          return {
            isCover: index == 0,
            path: base64
          }
        })
        return add({
          ...form,
          id: undefined,
          prim_prod: id,
          figures
        })
          .then((res) => {
            if (typeof res === 'object') {
              res.type = 'add'
            }
            return res
          })
          .catch((res) => {
            return res
          })
      })
    },
    doEdit(styleList) {
      const editArr = styleList.filter(({ id }) => {
        return this.oStructs.some((item) => {
          if (this.isCustomId(id)) return false
          return item.id == id
        })
      })
      return editArr.map(({ id, psd_file, figures, ...form }) => {
        return edit(id, {
          ...form
        })
      })
    },
    async doFiguresFunc(styleList, oStructsObj) {
      let p = []
      let tempArr = []
      styleList.forEach(cStyle => {
        let style = deepClone(cStyle)
        let oStyleDisplayImageIdList = oStructsObj[style.id]?.styleDisplayImageList?.map(({ id }) => id) || []
        p.push((async () => {
          style.styleDisplayImageList = await handleFigurePaths({
            list: style.styleDisplayImageList,
            prop: 'displayImagePath'
          })
          let nStyleDisplayImageIdList = (style.styleDisplayImageList || []).map((item, index) => {
            item.sort = index
            return item.id
          })
          style.displayImageDelIdList = oStyleDisplayImageIdList.filter(id => !nStyleDisplayImageIdList.includes(id))
        })())
        tempArr.push(style)
      })
      await promiseAll(p)
      return tempArr
    }
  }
}
</script>
<style lang="scss" scoped>
.structFormComponent {
  ::v-deep {
    .is-disabled {
      .el-icon-close {
        opacity: 0;
      }
    }
  }
}
</style>
