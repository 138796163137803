<template>
  <div class="head-container head-form-container shelves-header">
    <BaseForm uiid="zd-search" customClass="customClass" ref="form" label-width="80px" :cols="formField" :form="query" @change="to">
      <template #nameSlot="{ scoped: { prop, placeholder } }">
        <el-input v-model="query[prop]" :placeholder="placeholder"></el-input>
      </template>

      <template #categorySlot="{ scoped: { prop, placeholder } }">
        <ProtoTypeTreeSelect
          size="mini"
          style="width: 100%"
          :placeholder="placeholder"
          :selectedValue.sync="query[prop]"
          :maxHeight="150"
          @change="to"
        />
      </template>
      <template #sexSlot="{ scoped: { prop, placeholder } }">
        <selectList :placeholder="placeholder" :options="SEX_LIST" :keyword.sync="query[prop]" @change="to" />
      </template>

      <!-- 负责人 -->
      <template #ownerIdSlot="{ scoped: { prop, placeholder } }">
        <el-select v-model="query[prop]" :placeholder="placeholder" @change="to" clearable>
          <el-option v-for="{ id, accountName } in childrenList" :key="id" :value="id" :label="accountName"></el-option>
        </el-select>
      </template>
      <!-- 分配状态 -->
      <template #isDistributeSlot="{ scoped: { prop, placeholder } }">
        <el-select v-model="query[prop]" :placeholder="placeholder" @change="to" clearable>
          <el-option :value="1" label="已分配"></el-option>
          <el-option :value="0" label="未分配"></el-option>
        </el-select>
      </template>
      <template #seasonSlot="{ scoped: { prop, placeholder } }">
        <selectList :placeholder="placeholder" :options="SEASONS_LSIT" :keyword.sync="query[prop]" @change="to" />
      </template>

      <template #timeSlot="{ scoped: { prop } }">
        <SaleDateTime :time.sync="time" @change="to" />
      </template>

      <template #levelSlot="{ scoped: { prop, placeholder } }">
        <selectList :placeholder="placeholder" :options="LEVEL_LSIT" :keyword.sync="query[prop]" @change="to" />
      </template>

      <template #statusSlot="{ scoped: { prop, placeholder } }">
        <selectList :placeholder="placeholder" :options="STATUS_LSIT" :keyword.sync="query[prop]" @change="to" />
      </template>

      <template #shelvesSlot="{ scoped: { prop, placeholder } }">
        <selectList :placeholder="placeholder" :options="SHELVES_LSIT" :keyword.sync="query[prop]" @change="to" />
      </template>

      <template #tortSlot>
        <el-select v-model="query.isTort" placeholder="请选择" @change="toQuery" clearable>
          <el-option v-for="{ value, label } in tortList" :value="value" :label="label" :key="value"></el-option>
        </el-select>
      </template>

      <template #managerSlot="{ scoped: { prop, placeholder } }">
        <selectList
          filterable
          :keyObj="keyObj"
          :placeholder="placeholder"
          :menus="accountList"
          :keyword.sync="query[prop]"
          @change="to"
        />
      </template>
      <template #size_psdSlot="{ scoped: { prop, placeholder } }">
        <selectList
          :placeholder="placeholder"
          :options="[
            {
              id: 0,
              name: '未上传psd'
            },
            { id: 1, name: '已上传psd' }
          ]"
          :keyword.sync="query[prop]"
          @change="to"
        />
      </template>
      <template #doneSlot>
        <el-button uiid="zd-submit" size="small" type="primary" icon="el-icon-search" @click="to"></el-button>
      </template>
    </BaseForm>
  </div>
</template>

<script>
import ProtoTypeTreeSelect from '@/components/protoTypeTreeSelect'
import SaleDateTime from '@/components/saleDateTime'
import { commonEheaderMixin } from '@/mixins'
import { objBeArray } from '@/utils'
import {
  SEX_LIST,
  LEVEL_LSIT,
  SEASONS_LSIT,
  PRODUCT_SHELVES_STATUS_LIST,
  PRODUCT_SHELVES_STATUS_LIST1,
  PRODUCT_SHELVES_LIST
} from '@/utils/constant'
import { getSubAccount } from '@/api/subAccount'
import { mapGetters } from 'vuex'

export default {
  props: {
    formField: {
      type: Array,
      required: true
    },
    childrenList: {
      type: Array,
      required: true
    }
  },
  mixins: [commonEheaderMixin],

  components: {
    ProtoTypeTreeSelect,
    SaleDateTime
  },
  data() {
    return {
      SEX_LIST: objBeArray(SEX_LIST),
      LEVEL_LSIT: objBeArray(LEVEL_LSIT),
      SEASONS_LSIT: objBeArray(SEASONS_LSIT),
      STATUS_LSIT: objBeArray(PRODUCT_SHELVES_STATUS_LIST1),
      SHELVES_LSIT: objBeArray(PRODUCT_SHELVES_LIST),
      time: [],
      keyObj: {
        label: 'accountName',
        value: 'id'
      },
      accountList: [],
      tortList: [
        { label: '全部', value: 'all' },
        { label: '是', value: 1 },
        { label: '否', value: 0 }
      ]
    }
  },
  methods: {
    async to() {
      const [err, valid] = await awaitWrap(this.$refs.form.validate())
      if (!valid) return
      this.toQuery()
    }
  },
  computed: {
    ...mapGetters(['isMain'])
  },
  // created() {
  //   if (this.isMain) {
  //     getSubAccount().then((res) => {
  //       this.accountList = res.detail
  //     })
  //   }
  // }
}
</script>
<style lang="scss" scoped>
.shelves-header {
  ::v-deep {
    .customClass {
      .el-form.el-form {
        padding: 0;
      }
      .el-form-item__label {
        white-space: nowrap;
      }
    }
  }
}
</style>
