<template>
  <div class="saleDateTimeComponent">
    <el-radio-group v-model="label" size="mini" v-if="howDate.length" @change="labelChange">
      <!-- <el-radio label="1" border>今日</el-radio>
      <el-radio label="2" border>昨日</el-radio>
      <el-radio label="3" border>七日</el-radio> -->
      <el-radio v-for="{ label, value } in howDate" :key="label" :label="value" border>{{ label }}</el-radio>
    </el-radio-group>
    <el-date-picker
      v-model="value"
      type="daterange"
      size="mini"
      range-separator="/"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      value-format="yyyy-MM-dd"
      @change="valueChange"
    >
    </el-date-picker>
  </div>
</template>

<script>
import { debounce, parseCharacterTime } from '@/utils'
import cloneDeep from 'lodash/cloneDeep'
import { validatenull } from '@/components/avue/utils/validate'
const TIME_PROPS_OBJ = {
  create_time: {
    start: 'createStartTime',
    end: 'createEndTime'
  },
  createTime: {
    start: 'startCreateTime',
    end: 'endCreateTime'
  },
  put_on_time: {
    start: 'startOnTime',
    end: 'endOnTime'
  },
  off_time: {
    start: '',
    end: ''
  },
  importTime: {
    start: 'importStartTime',
    end: 'importEndTime'
  },
  time: {
    start: '',
    end: ''
  },
  supplier_create_time: {
    start: 'createStartTime',
    end: 'createEndTime'
  },
  sendTime: {
    start: 'sendStartTime',
    end: 'sendEndTime'
  },
  exportTime: {
    start: 'exportStartTime',
    end: 'exportEndTime'
  },
  expressWaybillDownloadTime: {
    start: 'expressWaybillDownloadStartTime',
    end: 'expressWaybillDownloadEndTime'
  },
  default: {
    start: 'startCreateTime',
    end: 'endCreateTime'
  }
}
const TIME_PROPS = Object.keys(TIME_PROPS_OBJ)

export default {
  props: {
    time: {
      type: Array | String,
      default: () => []
    },
    model: Object,
    timeProp: {
      type: String,
      default: 'importTime'
    },
    timeProps: Object,
    howDate: {
      type: Array,
      default: () => {
        return [
          {
            label: '今日',
            value: '1'
          },
          {
            label: '昨日',
            value: '2'
          }
        ]
      }
    }
  },
  data() {
    let { time } = this
    time = Array.isArray(time) ? time : []
    return {
      value: time,
      label: null
    }
  },
  watch: {
    time(n) {
      if (validatenull(n)) {
        this.value = []
        this.label = ''
      }
    }
  },
  computed: {
    finalTimeProps({ timeProps, timeProp }) {
      if (timeProps) return timeProps
      return TIME_PROPS_OBJ[timeProp] || TIME_PROPS_OBJ.default
    }
  },
  methods: {
    updateValue(val) {
      this.changeModel(val)
      this.$emit('update:time', val)
      this.$emit('change', val)
    },
    changeModel(time) {
      let { model, finalTimeProps } = this
      if (model) {
        let { start, end } = finalTimeProps
        let tempObj = {
          [start]: undefined,
          [end]: undefined
        }
        if (Array.isArray(time) && time.length) {
          tempObj[start] = parseCharacterTime(new Date(`${time[0]} 00:00:00`))
          tempObj[end] = parseCharacterTime(new Date(`${time[1]} 23:59:59`))
        }
        for (const key in tempObj) {
          this.$set(model, key, tempObj[key])
        }
        // Object.assign(model, tempObj)
        // console.log(model, tempObj, time, finalTimeProps)
      }
    },

    labelChange(newVal) {
      let val = cloneDeep(newVal)
      if (val === '1') {
        val = []
        const time = parseCharacterTime(new Date())
        val[0] = time.split(' ')[0]
        val[1] = time.split(' ')[0]
        this.value = val
      }
      if (val === '2') {
        val = []
        let dateTime = new Date()
        dateTime = dateTime.setDate(dateTime.getDate() - 1)
        dateTime = new Date(dateTime)
        const time = parseCharacterTime(dateTime)
        val[0] = time.split(' ')[0]
        val[1] = time.split(' ')[0]
        this.value = val
      }
      if (val === '3') {
        val = []
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
        val[0] = parseCharacterTime(start).split(' ')[0]
        val[1] = parseCharacterTime(end).split(' ')[0]
        console.log(val, 'val')
        this.value = val
      }
      this.updateValue(val)
      return val
    },
    valueChange(newVal) {
      newVal = newVal || []
      let time = []
      const today = parseCharacterTime(new Date()) || []
      time[0] = today.split(' ')[0]
      time[1] = today.split(' ')[0]
      if (newVal[0] === time[0] && newVal[1] === time[1]) {
        this.label = '1'
        this.updateValue(newVal)
        return
      }

      let yTime = []

      let dateTime = new Date()
      dateTime = dateTime.setDate(dateTime.getDate() - 1)
      dateTime = new Date(dateTime)
      const yesDay = parseCharacterTime(dateTime)
      yTime[0] = yesDay.split(' ')[0]
      yTime[1] = yesDay.split(' ')[0]

      if (newVal[0] === yTime[0] && newVal[1] === yTime[1]) {
        this.label = '2'
        this.updateValue(newVal)
        return
      }

      let wTime = []
      let weekTime = new Date()
      weekTime = weekTime.setDate(weekTime.getDate() - 7)
      weekTime = new Date(weekTime)
      let nowTime = new Date()
      nowTime = parseCharacterTime(nowTime)
      const weekDay = parseCharacterTime(weekTime)
      wTime[0] = weekDay.split(' ')[0]
      wTime[1] = nowTime.split(' ')[0]

      if (newVal[0] === wTime[0] && newVal[1] === wTime[1]) {
        this.label = '3'
        this.updateValue(newVal)
        return
      }

      this.updateValue(newVal)
      this.label = null
    }
  }
}
</script>

<style lang="scss" scoped>
.saleDateTimeComponent {
  position: relative;

  display: flex;
  justify-content: flex-start;
  .el-radio-group {
    flex: 0 0 auto;
    margin-right: 12px;
  }
  .el-date-editor {
    flex: 1;
  }
  ::v-deep {
    .el-radio__input {
      display: none;
    }
    .el-radio--small.is-bordered {
      height: 32px;
      line-height: 1;
      padding: 8px 11px 0 0;
      margin: 0;
      .el-radio__label {
        padding-left: 11px;
        font-size: 14px;
      }
    }
    .el-radio--mini.is-bordered {
      height: 28px;
      line-height: 17px;
      padding: 5px 5px 0 0;
      margin: 0 5px 0 0;
    }
    .el-radio__label {
      padding-left: 5px;
    }
    .el-date-editor--daterange.el-input__inner {
      width: auto;
    }
  }
}
</style>
