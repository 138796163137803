import { REQUEST_ALL_DATA } from '@/utils/constant'
import { cloneDeep } from 'lodash'

export default function (option = {}) {
  const { checkoutAllApi } = option
  return {
    data() {
      return {
        allCheckedSelectedData: []
      }
    },

    methods: {
      getAllCheckedSelectedDataApi(data = {}) {
        const func = checkoutAllApi || this.checkoutAllApi
        if (!func) throw new Error('获取所有数据接口不能为空')
        return func({
          ...(this.params || {}),
          ...data,
          ...REQUEST_ALL_DATA
        })
      },

      async getAllSelectedDataData(cacheSyncData = []) {
        try {
          const { checkAllData4 } = this.$refs
          if (!checkAllData4) return
          const [err, data] = await checkAllData4.getSmartAllData(cacheSyncData)
          if (!data.length) {
            this.$message.warning('请先选择数据，再进行此操作!')
            return [true, []]
          }
          if (!err) {
            this.allCheckedSelectedData = cloneDeep(data)
          }
          return [err, data]
        } catch (err) {
          console.log('err', err)
          return [true, []]
        }
      },

      clearAllSelectedData() {
        const { checkAllData4 } = this.$refs
        if (checkAllData4) {
          checkAllData4.resetData()
        }
      }
    }
  }
}
