<template>
  <div class="setPriceTable">
    <el-form :model="priceFormData" :rules="priceFormRules" ref="setPriceTable" :validate-on-rule-change="false">
      <el-table :data="priceFormData.list" border class="table-border" :header-row-class-name="tableRowClassName">
        <template v-for="({ prop, label, minWidth }, index) in priceColData">
          <el-table-column v-if="['size', 'lowerLimitPrice'].includes(prop)" :key="index" :prop="prop" :label="label" align="center">
          </el-table-column>
          <el-table-column v-else :key="index" :prop="prop" :label="label" align="center" :min-width="minWidth">
            <template v-slot="{ row, $index }">
              <el-form-item :prop="prop + $index" v-if="isInputVisible">
                <el-input
                  :uiid="`zd-${$index}-${prop}`"
                  v-model="priceFormData[`${prop}${$index}`]"
                  :disabled="isSet"
                  class="tableInput"
                  @change="onchange(prop)"
                ></el-input>
              </el-form-item>
              <span v-else>{{ row[`${prop}`] || '暂无' }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { accDiv, accMul } from '@/utils'

var priceReg = /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/
var validatePrice = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('价格不能为空!'))
  }
  if (!priceReg.test(value)) {
    callback(new Error('价格必须为数值且不能少于0'))
  } else {
    callback()
  }
}
export default {
  props: {
    priceData: Array,
    priceColData: Array,
    isSet: {
      type: Boolean,
      default: true
    },
    isInputVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    var ValidatePrice2Factory = (rowIndex) => {
      let formKey = `lowerLimitPrice${rowIndex}`
      return (rule, value, callback) => {
        let { priceFormData, lowestDiscount } = this
        let price = Number(priceFormData[formKey])
        // 获取最低售价（保留2位小数）：Math.ceil(price / lowestDiscount * 100) / 100
        let lowestPrice = accDiv(Math.ceil(accMul(accDiv(price, lowestDiscount), 100)), 100)
        // console.log(value, lowestDiscount, price, lowestPrice, price / lowestDiscount)
        if (lowestPrice > value) {
          callback(new Error(`价格不低于${lowestPrice}`))
        } else {
          callback()
        }
      }
    }
    return {
      ValidatePrice2Factory,
      priceFormData: {
        list: this.priceData
      },
      priceFormRules: {}
    }
  },
  computed: {
    ...mapGetters(['memberLevelList']),
    lowestDiscount({ memberLevelList }) {
      return memberLevelList.reduce((prev, next) => {
        if (next.priceDiscount < prev) return next.priceDiscount
        return prev
      }, 1)
    }
  },
  watch: {
    lowestDiscount: {
      handler() {
        this.$nextTick(function () {
          this.validate()
        })
      },
      immediate: true
    },
    priceData: {
      handler(n, o) {
        let { priceData, priceColData, priceFormRules } = this
        priceFormRules = {}
        priceData.forEach((row, rowIndex) => {
          const { ids, priceIds, size, size_id, ...rest } = row
          const formKeys = Object.keys(rest).sort((a, b) => {
            return +a.replace('price', '') - +b.replace('price', '')
          })
          formKeys.map((key) => {
            priceFormRules[`${key}${rowIndex}`] = [{ required: true, validator: validatePrice, trigger: 'blur' }]
            if (key.includes('price')) {
              priceFormRules[`${key}${rowIndex}`].push({
                validator: this.ValidatePrice2Factory(rowIndex)
              })
            }
            this.$set(this.priceFormData, key + '' + rowIndex, row[key])
          })
        })
        // console.log(priceData, priceFormRules)
        this.priceFormRules = priceFormRules

        if (n !== o) {
          this.$nextTick(function () {
            this.validate()
          })
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.$emit('takeRef', this.$refs.setPriceTable, this.priceFormData)
  },
  methods: {
    onchange(prop) {
      // console.log(prop)
      if (prop.includes('lowerLimitPrice')) {
        this.validate()
      }
    },
    
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === 0) {
        return 'tableHeader'
      }
    },

    validate() {
      return this.$refs.setPriceTable.validate()
    }
  }
}
</script>

<style scoped lang="scss">
.setPriceTable {
  margin-top: 10px;
  .table-border {
    border-left: 1px solid #ebeef5;
  }
  .el-table {
    border-collapse: collapse !important;
    ::v-deep {
      .tableHeader th {
        border: 1px solid #ebeef5 !important;
      }
    }
  }
}
</style>
