var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"app-container shelvesPage"},[_vm._t("top",null,{"sup_this":_vm.sup_this}),(_vm.isSearch)?_c('EHeader',{attrs:{"sup_this":_vm.sup_this,"query":_vm.query,"formField":_vm.formField,"childrenList":_vm.childrenList},on:{"searchChange":_vm.searchChange}}):_vm._e(),_vm._t("batchOperation",[_c('div',{staticStyle:{"min-height":"30px"}})],{"sup_this":_vm.sup_this}),_vm._t("tips",null,{"sup_this":_vm.sup_this}),(_vm.hasCheckAllData4)?_c('CheckAllData4',{ref:"checkAllData4",attrs:{"isOriginData":false,"isPageChange":_vm.isPageChange,"tableData":_vm.data,"page":_vm.page,"size":_vm.size,"total":_vm.total,"getAllDataApi":_vm.getAllCheckedSelectedDataApi,"selectionData":_vm.selectedData},on:{"update:isPageChange":function($event){_vm.isPageChange=$event},"update:is-page-change":function($event){_vm.isPageChange=$event},"update:selectionData":function($event){_vm.selectedData=$event},"update:selection-data":function($event){_vm.selectedData=$event}}}):_vm._e(),_c('div',{staticClass:"table-wrapper"},[_c('CommonTable',_vm._b({ref:"table",staticClass:"non-expand-icon",class:{ editCommonTable: _vm.isEdit },attrs:{"height":"100%","tableLoading":_vm.tableLoading,"cols":_vm.cols,"infoData":_vm.data,"span-method":_vm.spanMethod,"cell-class-name":_vm.cellClassName},on:{"selection-change":_vm.handleSelectionChange},scopedSlots:_vm._u([{key:"expandSlot",fn:function(ref){
var scoped = ref.scoped;
return [(scoped.$expandLevel === 1)?_c('div',{staticClass:"flex-middle expand-wrapper"},[_c('expandBtn',{attrs:{"isExpand":scoped.$tableExpanded},on:{"click":function($event){return _vm.handleExpandBtn(scoped.row)}}}),(scoped.type === 'imgText')?_c('div',{staticClass:"expand-text flex-middle"},[_c('base-image',{staticClass:"flex-none mr10",attrs:{"size":"60","imgSize":"60","src":_vm.figure(scoped),"fit":"contain"}}),_c('div',{staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(scoped.chineseName || '暂无名字')+" ")])],1):_c('div',{staticClass:"expand-text"},[_vm._v(_vm._s(scoped[scoped.prop]))])],1):(scoped.$expandLevel === 2)?[_vm._t("expandSlotLevel2",[_c('customTable',{attrs:{"getList":_vm.expandTableOption.getList,"resetMergeData":_vm.expandTableOption.getPostData(scoped),"option":_vm.expandTableOption},scopedSlots:_vm._u([{key:"menuLeft",fn:function(){return [_c('span',{staticClass:"text-black"},[_vm._v("编辑记录")])]},proxy:true},{key:"menu",fn:function(ref){
var row = ref.row;
return [_c('loadingBtn',{attrs:{"type":"text"},on:{"click":function($event){return _vm.expandTableOption.menuFn(row)}}},[_vm._v(_vm._s(_vm.expandTableOption.menuText))])]}}],null,true)})],{"scoped":scoped})]:_vm._e()]}},{key:"orCategorySlot",fn:function(ref){
var scoped = ref.scoped;
return [_vm._v(" "+_vm._s(_vm.getValueFromObj(scoped, 'productCategory.name') || '暂无')+" ")]}},{key:"mergeSlot",fn:function(ref){
var scoped = ref.scoped;
return [_c('div',{staticClass:"merge-slot-class"},[_c('defaultImg',{staticStyle:{"width":"60px","height":"60px"},attrs:{"src":_vm.figure(scoped)}}),_c('div',[_c('div',{staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(scoped.chineseName || '暂无名字')+" ")])])],1)]}},{key:"productCategorySlot",fn:function(ref){
var scoped = ref.scoped;
return [_vm._v(" "+_vm._s(_vm.getValueFromObj(scoped, 'productCategory.name') || '暂无')+" ")]}},{key:"structSlot",fn:function(ref){
var scoped = ref.scoped;
return [_vm._l((_vm.structs(scoped) || []),function(ref){
var name = ref.name;
var status = ref.status;
var id = ref.id;
return _c('el-tag',{key:id,staticClass:"tag-medium",attrs:{"type":"primary"}},[_vm._v(" "+_vm._s(name)+" "),(_vm.isShelves({ status: status }) && _vm.showProductStatus)?_c('span',{staticClass:"text-danger text-small"},[_vm._v("(已上架) ")]):_vm._e()])}),(_vm.isEditStruct)?_c('structEdit',{staticClass:"struct-edit",attrs:{"sup_this":_vm.sup_this,"data":scoped}}):_vm._e()]}},{key:"sexSlot",fn:function(ref){
var scoped = ref.scoped;
return [_c('el-tag',{staticClass:"tag-medium",attrs:{"type":"primary"}},[_vm._v(" "+_vm._s(_vm.SEX_LIST[scoped[scoped.prop] || 0])+" ")])]}},{key:"seasonSlot",fn:function(ref){
var scoped = ref.scoped;
return [_c('el-tag',{staticClass:"tag-medium",attrs:{"type":"primary"}},[_vm._v(" "+_vm._s(_vm.SEASONS_LSIT[scoped[scoped.prop] || 0])+" ")])]}},{key:"sizesSlot",fn:function(ref){
var scoped = ref.scoped;
return _vm._l((_vm.sizeList(scoped)),function(ref){
var sizeName = ref.sizeName;
var id = ref.id;
var status = ref.status;
return _c('el-tag',{key:id,staticClass:"tag-medium plr18",attrs:{"type":"primary"}},[_vm._v(" "+_vm._s(sizeName)+" "),(_vm.isShelves({ status: status }) && _vm.showProductStatus)?_c('span',{staticClass:"text-danger text-small"},[_vm._v("(已上架) ")]):_vm._e()])})}},{key:"priceSlot",fn:function(ref){
var scoped = ref.scoped;
return [_c('div',{staticClass:"flex-column"},[(_vm.hasPrice(scoped))?[_c('div',[_vm._v("￥"+_vm._s(_vm.minPrice(scoped))+" - ￥"+_vm._s(_vm.maxPrice(scoped)))]),(_vm.viewPriceBtn)?_c('viewPriceDialog',{attrs:{"data":scoped}}):_vm._e()]:_c('div',[_vm._v("暂无数据")])],2)]}},{key:"put_on_timeSlot",fn:function(ref){
var scoped = ref.scoped;
return [_c('span',[_vm._v(_vm._s(_vm.putOnTime(scoped)))])]}},{key:"base_sizesSlot",fn:function(ref){
var scoped = ref.scoped;
return [_c('span',[_vm._v(" "+_vm._s((scoped[scoped.prop] && scoped[scoped.prop].sizeName) || '暂无')+" ")])]}},{key:"mergeManageSlot",fn:function(ref){
var scoped = ref.scoped;
return [_c('div',{staticClass:"merge-slot-class"},[_c('div',[_c('div',[_vm._v(" "+_vm._s(scoped.name || '暂无名字')+" ")])])])]}},{key:"sizesSlotManage",fn:function(ref){
var scoped = ref.scoped;
return _vm._l((scoped[scoped.prop] || []),function(ref){
var sizeName = ref.sizeName;
var id = ref.id;
return _c('el-tag',{key:id,staticClass:"tag-medium",attrs:{"type":"primary"}},[_vm._v(" "+_vm._s(sizeName)+" ")])})}},{key:"structSlotManage",fn:function(ref){
var scoped = ref.scoped;
return _vm._l((_vm.structs(scoped) || []),function(ref){
var name = ref.name;
var id = ref.id;
return _c('el-tag',{key:id,staticClass:"tag-medium",attrs:{"type":"primary"}},[_vm._v(" "+_vm._s(name)+" ")])})}},{key:"isNewArriveSlot",fn:function(ref){
var scoped = ref.scoped;
return [(_vm.newArrive(scoped))?_c('span',{staticClass:"new-product-tag"},[_vm._v("新")]):_vm._e()]}},{key:"gearsCateTimeSlot",fn:function(ref){
var scoped = ref.scoped;
return [_c('span',[_vm._v(_vm._s(_vm.parseTime(scoped[scoped.prop])))])]}}])},'CommonTable',_vm.tableProps,false),[_vm._t("default",null,{"sup_this":_vm.sup_this})],2),(_vm.showDialog)?_c('BaseDialog',{staticClass:"view-price-dialog",staticStyle:{"text-align":"left"},attrs:{"dialogVisible":_vm.showDialog,"append-to-body":false,"modal-append-to-body":false,"width":"70vw","height":"395px","title":"原型价格"},on:{"update:dialogVisible":function($event){_vm.showDialog=$event},"update:dialog-visible":function($event){_vm.showDialog=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('span')]},proxy:true}],null,false,2261540193)},[_c('div',{staticClass:"price-wrapper"},[_c('div',{staticClass:"flex"},[_c('el-image',{staticClass:"product-image",attrs:{"src":_vm.cover,"fit":"cover"}}),_c('div',{staticClass:"product-name"},[_vm._v(_vm._s(_vm.product.name))])],1),_c('div',{staticClass:"table"},[_c('priceTable',{attrs:{"priceData":_vm.priceData,"priceColData":_vm.priceColData}})],1)])]):_vm._e()],1),_c('PaginationBar',{attrs:{"page":_vm.page,"size":_vm.size,"total":_vm.total},on:{"refreshTableEventFun":_vm.refreshTableEventFun}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }