<template>
  <section class="app-container shelvesPage">
    <slot name="top" :sup_this="sup_this"></slot>
    <EHeader
      v-if="isSearch"
      :sup_this="sup_this"
      :query="query"
      :formField="formField"
      @searchChange="searchChange"
      :childrenList="childrenList"
    />
    <slot name="batchOperation" :sup_this="sup_this">
      <div style="min-height: 30px"></div>
    </slot>
    <slot name="tips" :sup_this="sup_this"> </slot>

    <CheckAllData4
      v-if="hasCheckAllData4"
      ref="checkAllData4"
      :isOriginData="false"
      :isPageChange.sync="isPageChange"
      :tableData="data"
      :page="page"
      :size="size"
      :total="total"
      :getAllDataApi="getAllCheckedSelectedDataApi"
      :selectionData.sync="selectedData"
    />

    <div class="table-wrapper">
      <CommonTable
        ref="table"
        height="100%"
        class="non-expand-icon"
        :tableLoading="tableLoading"
        :cols="cols"
        :infoData="data"
        :class="{ editCommonTable: isEdit }"
        :span-method="spanMethod"
        :cell-class-name="cellClassName"
        @selection-change="handleSelectionChange"
        v-bind="tableProps"
      >
        <template #expandSlot="{ scoped }">
          <div v-if="scoped.$expandLevel === 1" class="flex-middle expand-wrapper">
            <expandBtn :isExpand="scoped.$tableExpanded" @click="handleExpandBtn(scoped.row)"></expandBtn>
            <div v-if="scoped.type === 'imgText'" class="expand-text flex-middle">
              <base-image
                class="flex-none mr10"
                size="60"
                imgSize="60"
                :src="figure(scoped)"
                fit="contain"
              ></base-image>
              <div style="max-width: 200px">
                {{ scoped.chineseName || '暂无名字' }}
              </div>
            </div>
            <div v-else class="expand-text">{{ scoped[scoped.prop] }}</div>
          </div>
          <template v-else-if="scoped.$expandLevel === 2">
            <slot name="expandSlotLevel2" :scoped="scoped">
              <customTable
                :getList="expandTableOption.getList"
                :resetMergeData="expandTableOption.getPostData(scoped)"
                :option="expandTableOption"
              >
                <template #menuLeft>
                  <span class="text-black">编辑记录</span>
                </template>
                <template #menu="{ row }">
                  <loadingBtn type="text" @click="expandTableOption.menuFn(row)">{{
                    expandTableOption.menuText
                  }}</loadingBtn>
                </template>
              </customTable>
            </slot>
          </template>
        </template>

        <template #orCategorySlot="{ scoped }">
          {{ getValueFromObj(scoped, 'productCategory.name') || '暂无' }}
        </template>

        <!-- 原型列表  展示图/原型名称-->
        <template #mergeSlot="{ scoped }">
          <div class="merge-slot-class">
            <defaultImg :src="figure(scoped)" style="width: 60px; height: 60px"></defaultImg>
            <div>
              <div style="max-width: 200px">
                {{ scoped.chineseName || '暂无名字' }}
              </div>
            </div>
          </div>
        </template>

        <!-- 原型列表 原型分类列 -->
        <template #productCategorySlot="{ scoped }">
          {{ getValueFromObj(scoped, 'productCategory.name') || '暂无' }}
        </template>

        <!-- 原型列表 款式列 -->
        <template #structSlot="{ scoped }">
          <el-tag class="tag-medium" type="primary" :key="id" v-for="{ name, status, id } in structs(scoped) || []">
            {{ name }}
            <span v-if="isShelves({ status }) && showProductStatus" class="text-danger text-small">(已上架) </span>
          </el-tag>
          <structEdit v-if="isEditStruct" :sup_this="sup_this" :data="scoped" class="struct-edit" />
        </template>

        <!-- 原型列表  适用人群列 -->
        <template #sexSlot="{ scoped }">
          <el-tag class="tag-medium" type="primary">
            {{ SEX_LIST[scoped[scoped.prop] || 0] }}
          </el-tag>
        </template>

        <!-- 原型列表  适用季节列-->
        <template #seasonSlot="{ scoped }">
          <el-tag class="tag-medium" type="primary">
            {{ SEASONS_LSIT[scoped[scoped.prop] || 0] }}
          </el-tag>
        </template>

        <!-- 原型列表 尺码列 -->
        <template #sizesSlot="{ scoped }">
          <el-tag
            class="tag-medium plr18"
            type="primary"
            :key="id"
            v-for="{ sizeName, id, status } in sizeList(scoped)"
          >
            {{ sizeName }}
            <span v-if="isShelves({ status }) && showProductStatus" class="text-danger text-small">(已上架) </span>
          </el-tag>
        </template>

        <!-- 原型列表 价格列 -->
        <template #priceSlot="{ scoped }">
          <div class="flex-column">
            <template v-if="hasPrice(scoped)">
              <div>￥{{ minPrice(scoped) }} - ￥{{ maxPrice(scoped) }}</div>
              <viewPriceDialog v-if="viewPriceBtn" :data="scoped"></viewPriceDialog>
            </template>
            <div v-else>暂无数据</div>
          </div>
        </template>

        <!-- 原型列表 上架日期列 -->
        <template #put_on_timeSlot="{ scoped }">
          <span>{{ putOnTime(scoped) }}</span>
        </template>

        <!-- 原型列表 参考尺码列 -->
        <template #base_sizesSlot="{ scoped }">
          <span> {{ (scoped[scoped.prop] && scoped[scoped.prop].sizeName) || '暂无' }} </span>
        </template>

        <template #mergeManageSlot="{ scoped }">
          <div class="merge-slot-class">
            <div>
              <div>
                {{ scoped.name || '暂无名字' }}
              </div>
            </div>
          </div>
        </template>

        <template #sizesSlotManage="{ scoped }">
          <el-tag class="tag-medium" type="primary" :key="id" v-for="{ sizeName, id } in scoped[scoped.prop] || []">
            {{ sizeName }}
          </el-tag>
        </template>

        <template #structSlotManage="{ scoped }">
          <el-tag class="tag-medium" type="primary" :key="id" v-for="{ name, id } in structs(scoped) || []">
            {{ name }}
          </el-tag>
        </template>

        <template #isNewArriveSlot="{ scoped }">
          <span v-if="newArrive(scoped)" class="new-product-tag">新</span>
        </template>

        <template #gearsCateTimeSlot="{ scoped }">
          <span>{{ parseTime(scoped[scoped.prop]) }}</span>
        </template>

        <slot :sup_this="sup_this"></slot>
      </CommonTable>

      <!-- 已上架原型 查看价格弹出框 -->
      <BaseDialog
        v-if="showDialog"
        style="text-align: left"
        :dialogVisible.sync="showDialog"
        :append-to-body="false"
        :modal-append-to-body="false"
        width="70vw"
        height="395px"
        title="原型价格"
        class="view-price-dialog"
      >
        <div class="price-wrapper">
          <div class="flex">
            <el-image :src="cover" fit="cover" class="product-image" />
            <div class="product-name">{{ product.name }}</div>
          </div>
          <div class="table">
            <priceTable :priceData="priceData" :priceColData="priceColData"></priceTable>
          </div>
        </div>
        <template #footer>
          <span></span>
        </template>
      </BaseDialog>
    </div>
    <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
  </section>
</template>

<script>
// import SwiperWrapper from '@/components/swiperWrapper'
import priceTable from '../module/setPrice'
import EHeader from './module/header'
import ProtoType from './module/protoType'
import Edit from './module/edit'
import SizesEdit from './module/sizesEdit'
import PsdBtn from './module/psdBtn'
import StructEdit from './module/structEdit'
import expandBtn from '@/views/components/expandBtn'
import customTable from '@/views/components/customTable'
import viewPriceDialog from '@/views/product/baseProductPage/module/viewPriceDialog'
import CheckAllData4 from '@/views/components/checkAllData4'

import checkAll4Mixin from '@/views/components/checkAllData4/checkAll4Mixin'

import { initDataMixin } from '@/mixins'
import { SEX_LIST, SEASONS_LSIT, PIC_SIZE, SHELVES, OFF_SHELVES, WAIT_SHELVES, WAIT_PERFECT } from '@/utils/constant'
import { getValueFromObj, parseTime, parseImgSrc, flatMapDeepByArray } from '@/utils'
import { vaildData, deepClone } from '@/components/avue/utils/util'
import { validatenull } from '@/components/avue/utils/validate'
import { getPrototypeList } from '@/api/product/protoApi'
import { mapGetters } from 'vuex'
import { map } from 'lodash'

export default {
  mixins: [
    initDataMixin,
    checkAll4Mixin({
      checkoutAllApi: getPrototypeList
    })
  ],
  props: {
    cols: {
      type: Array,
      required: true
    },
    formField: {
      type: Array,
      required: true
    },
    status: {
      type: [String, Number]
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    isEditStruct: Boolean,
    resetUrl: String,
    viewPriceBtn: Boolean,
    isSearch: Boolean,
    expandTableOption: Object,
    isMultipleReferSize: {
      type: Boolean,
      default: true
    },
    referSizeSpanCol: {
      type: Array,
      default: () => ['参考尺码', '尺码', '操作']
    },
    childrenList: {
      type: Array,
      default: () => []
    },
    resetMergeData: {
      type: Object,
      default: () => ({})
    },

    showProductStatus: {
      type: Boolean,
      default: true
    },

    hasCheckAllData4: Boolean
  },
  components: {
    expandBtn,
    customTable,
    // SwiperWrapper,
    ProtoType,
    priceTable,
    EHeader,
    SizesEdit,
    StructEdit,
    PsdBtn,
    Edit,
    viewPriceDialog,
    CheckAllData4
  },
  data() {
    return {
      PIC_SIZE,
      SEX_LIST,
      SEASONS_LSIT,
      sup_this: this,
      url: '/externaladmin/prototypeService/productPrototype/list',
      mergeData: {
        orderItems: [
          {
            asc: false,
            column: 'create_time'
          },
          {
            asc: false,
            column: 'id'
          }
        ],
        status: this.status
      },
      showDialog: false,
      product: {},
      proLen: 0,
      swiperOption: {
        slidesPerView: 'auto',
        autoplay: false,
        pagination: {},
        navigation: {}
      },
      priceData: [],
      priceColData: [],
      dataFilterForm: {
        structName: '',
        psdStatus: ''
      },
      assignData: []
    }
  },
  created() {
    if (this.resetUrl) {
      this.url = this.resetUrl
    }
  },

  computed: {
    ...mapGetters(['isMain']),

    putOnTime() {
      return (scoped) => {
        const onTime = $GET(scoped, 'productPrototypePlatform.onTime', '')
        if (!onTime) return '暂无'
        return parseTime(onTime)
      }
    },

    tableProps({ $attrs, hasExpandRow }) {
      let tempObj = {}
      if (hasExpandRow) {
        tempObj.rowKey = hasExpandRow ? 'customId' : undefined
      }
      return {
        ...$attrs,
        ...tempObj,
        selection: vaildData($attrs.selection, true)
      }
    },
    hasExpandRow({ cols }) {
      return cols.some(({ slotName }) => slotName === 'expandSlot')
    },
    expandColumnProp({ cols }) {
      return (cols || []).find(({ slotName }) => slotName === 'expandSlot')?.prop
    },
    hasPrice() {
      return (row) => {
        return (row.$sizeList || row.sizeList)?.[0]?.sizeLevelCategoryPriceList?.length
      }
    },
    minPrice() {
      return (data) => {
        const priceList = map(flatMapDeepByArray(data.$sizeList, ['sizeLevelCategoryPriceList']), 'price')
        return Math.min(...priceList)
      }
    },
    maxPrice() {
      return (data) => {
        const priceList = map(flatMapDeepByArray(data.$sizeList, ['sizeLevelCategoryPriceList']), 'price')
        return Math.max(...priceList)
      }
    },
    isShelves() {
      return (data) => {
        return data.status == SHELVES
      }
    },
    cover() {
      try {
        console.log('this.product.styleList[0]', this.product.styleList[0])
        if (!this.product.styleList.length) {
          return require('@/assets/images/default.png')
        }
        return this.aLiCompressPicByUrl(this.product.styleList[0].displayImageUrl, 80)
      } catch (err) {
        return require('@/assets/images/default.png')
      }
    },
    primStruct() {
      return (row) => {
        if (row.curIndex === undefined) {
          this.$set(row, 'curIndex', 0)
        }
        return row.$styleList ? row.$styleList[row.curIndex] || {} : {}
      }
    },
    // primStructSwiperData({ primStruct }) {
    //   let defaultShowImg = [
    //     {
    //       image: require('@/assets/images/default.png'),
    //       noPath: true
    //     }
    //   ]
    //   return (row) => {
    //     let showImageGroupItemList = primStruct(row).showImageGroupList?.[0]?.showImageGroupItemList
    //     return validatenull(showImageGroupItemList) ? defaultShowImg : showImageGroupItemList
    //   }
    // },
    structs({ dataFilterForm: { structName } }) {
      return (row) => {
        return row.styleList?.filter(({ name }) => {
          if (!structName) return true
          structName.lastIndex = 0
          return structName.test(name)
        })
      }
    },
    sizeList({ dataFilterForm: { psdStatus } }) {
      if (![undefined, null].includes(psdStatus)) psdStatus = psdStatus + ''
      return (row) => {
        let sizeList = row[row.prop]
        sizeList = sizeList || []
        return sizeList?.filter(({ psdPath }) => {
          if (!psdStatus) return true
          if (psdStatus === '0') {
            return !psdPath
          } else if (psdStatus === '1') {
            return psdPath
          }
        })
      }
    },
    figure() {
      return (row) => this.aLiCompressPicByUrl(getValueFromObj(row, 'styleList[0].styleDisplayImageList[0].displayImagePath'), 80)
    },

    newArrive() {
      return (row) => {
        return !!$GET(row, 'productPrototypePlatform.isNewArrive', false)
      }
    }
  },
  methods: {
    parseTime,
    getValueFromObj,

    checkAllData4Change(checked = false) {
      const {
        $refs: { checkAllData4 }
      } = this
      if (!checkAllData4) return
      checkAllData4.changeHandler(checked)
    },

    searchChange() {
      let { size_psd, structName } = this.query
      this.dataFilterForm.psdStatus = size_psd
      this.dataFilterForm.structName = structName ? new RegExp(structName.trim(), 'img') : ''
    },
    async tabInit() {
      await this.init()
      this.$nextTick(function () {
        this.$refs['table'].doLayout()
      })
    },
    /* toggleCurIndexByStruct(row, index) {
      this.$set(row, 'curIndex', index)
      if (this.$refs.swiperWrapper) {
        this.$refs.swiperWrapper.swiper.slideTo(0, 100, false)
      }
    }, */
    initCallBack() {
      let { data } = this
      //保存一份未处理的data
      if (this.isMultipleReferSize && data.some((item) => item.referSizeList)) {
        let tempArr = []
        data.forEach((item) => {
          let sizeList = $GET(item, 'sizeList', [])
          let referSizeList = item.referSizeList
          if (referSizeList) {
            let referSizeIdList = referSizeList.map(({ id }) => id)

            let moreArr = []
            let relationSizeList = [] // 已关联尺码
            let noRelationSizeList = [] // 未关联尺码
            item.sizeList?.forEach((size) => {
              if (referSizeIdList.includes(size.referSizeId)) {
                relationSizeList.push(size)
              } else {
                noRelationSizeList.push(size)
              }
            })

            referSizeList.forEach((referSize) => {
              let nItem = deepClone(item)
              nItem.referSize = referSize
              nItem.referSizeId = referSize.id

              let sizeList = relationSizeList?.filter((size) => size.referSizeId === nItem.referSizeId)
              nItem.sizeList = sizeList.length ? sizeList : []

              let sizeCheckList = item.sizeCheckList?.filter((size) => size.referSizeId === nItem.referSizeId)
              nItem.sizeCheckList = sizeCheckList && sizeCheckList.length ? sizeCheckList : null

              nItem.styleList?.forEach((style) => {
                style.showImageGroupList = style.showImageGroupList?.filter(
                  (img) => img.referSizeId === nItem.referSizeId
                )
              })

              moreArr.push(nItem)
            })

            // 未关联尺码自成一列
            if (noRelationSizeList.length) {
              item.sizeList = noRelationSizeList
              moreArr.push(item)
            }

            // 合并相同列
            let moreLen = moreArr.length
            moreArr.forEach((row, index) => {
              row.$sizeList = sizeList
              row.$styleList = item.styleList || []
              row.$referSizeIndex = index
              row.$referSizeLen = moreLen
            })
            tempArr = tempArr.concat(moreArr)
          } else {
            item.$sizeList = sizeList
            tempArr.push(item)
          }
        })
        this.data = tempArr
      } else {
        data.map((item) => {
          // item.$sizeList = this.filterLevelPriceList(item.sizeList, item.levelCategoryId)
          this.$set(item, 'curIndex', 0)
          return item
        })
      }
      this.data = this.data.map((item) => {
        if (this.hasExpandRow) {
          item.customId = item.id
          this.$set(item, '$expandLevel', 1)
          this.$set(item, '$tableExpanded', false)
        }

        this.$set(item, 'curIndex', 0)
        return item
      })
      this.handleTableData(this.data)
    },
    // 查看价格 col
    formatPriceCols(sizeList) {
      const priceColData = [{ prop: 'size', label: '尺寸' }]
      console.log('sizeList', sizeList)
      sizeList.some((size) => {
        const { sizeLevelCategoryPriceList } = size
        if (!sizeLevelCategoryPriceList || sizeLevelCategoryPriceList.length === 0) return false
        let num = -1
        ;(sizeLevelCategoryPriceList || []).map((item, index) => {
          num++
          const { levelCategoryItem, id } = item
          const { maxCount, minCount } = levelCategoryItem || { maxCount: 0, minCount: 0 }
          let label =
            index !== sizeLevelCategoryPriceList.length - 1
              ? `${minCount}-${maxCount}件价格（元）`
              : `${minCount}+件价格（元）`
          priceColData.push({
            prop: `price${num + 1}`,
            label,
            id
          })
        })
        return true
      })
      this.priceColData = priceColData
      console.log('this.priceColData', this.priceColData)
    },
    // 查看价格表格的数据
    formatPriceData(sizeList) {
      let tmpObj = {}
      ;(sizeList || []).map((item) => {
        const { sizeName, sizeLevelCategoryPriceList } = item
        if (!tmpObj[sizeName]) {
          tmpObj[sizeName] = {
            data: []
          }
        }
        ;(sizeLevelCategoryPriceList || []).map(({ price, levelCategoryItem }) => {
          if (levelCategoryItem) {
            const id = levelCategoryItem.id
            tmpObj[sizeName].data.push({
              levelCategoryItem: id,
              price
            })
          }
        })
      })
      const tmpArr = []
      for (let key in tmpObj) {
        let obj = {}
        obj.size = key
        const data = tmpObj[key].data.sort((a, b) => {
          return a.levelCategoryItem - b.levelCategoryItem
        })
        ;(data || []).map((item, index) => {
          obj[`price${index + 1}`] = item.price
        })
        tmpArr.push(obj)
      }
      this.priceData = tmpArr
      console.log('this.priceData', this.priceData)
    },
    // 切换表格就 重制新的 col 和 数据
    initTabelData(data) {
      const { $sizeList } = data
      this.formatPriceCols($sizeList)
      this.formatPriceData($sizeList)
    },
    spanMethod({ row, column }) {
      if (row.$onlyOne) {
        if (column.property === this.expandColumnProp) {
          return [1, row.$columnLen]
        } else {
          return [0, 0]
        }
      }
      if (row.$referSizeLen) {
        if (this.referSizeSpanCol.includes(column.label)) {
          return [1, 1]
        } else {
          if (row.$referSizeIndex === 0) {
            return [row.$referSizeLen, 1]
          } else {
            return [0, 0]
          }
        }
      }
      return [1, 1]
    },
    // 添加某一列类名
    cellClassName({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 8) {
        return 'referSizeClass'
      }
      if (row.$expandLevel === 2) return 'expand-level-2'
    },
    async handleExpandBtn(row) {
      let $columnLen = this.cols.length + 1
      if (this.tableProps.selection) $columnLen++
      this.$set(row, 'children', [
        {
          parent: row,
          parentId: row.id,
          customId: `${row.id}>1`,
          $expandLevel: 2,
          $onlyOne: true,
          $columnLen
        }
      ])
      row.$tableExpanded = !row.$tableExpanded
      this.$nextTick(function () {
        this.$refs.table.$refs.table.toggleRowExpansion(row, row.$tableExpanded)
      })
      // return new Promise(() => {})
    },

    filterLevelPriceList(sizeList, levelCategoryId) {
      if (validatenull(sizeList)) return []
      return sizeList.map((size) => {
        if (!validatenull(size.sizeLevelCategoryPriceList)) {
          size.sizeLevelCategoryPriceList = size.sizeLevelCategoryPriceList?.filter((item) => {
            return item.levelCategoryItem?.categoryId === levelCategoryId
          })
        }
        return size
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.shelvesPage {
  display: flex;
  flex-direction: column;
  .el-dialog__footer {
    ::v-deep {
      .el-button {
        display: none;
      }
    }
  }
  .price-wrapper {
    padding-left: 26px;
    margin-top: 30px;
    .link {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .href {
      color: $color-primary;
    }
    .table {
      margin-top: 41px;
    }
    .flex {
      display: flex;
      .product-image {
        width: 60px;
        height: 60px;
      }
      .product-name {
        margin-left: 12px;
        color: #ff6f30;
        height: 60px;
        line-height: 60px;
      }
    }
  }
  .table-wrapper {
    flex: 1;
    overflow: hidden;
    .flex-column {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .merge-slot-class {
    position: relative;
    display: flex;
    align-items: center;
    .el-image {
      margin-right: 10px;
      background: $border-color;
    }
    .customs-code {
      color: $color-warning;
      font-size: 12px;
    }
  }
  .structure-list {
    margin-top: 10px;
    display: flex;
    .structure {
      border: 1px solid $border-color;
      padding: 2px 5px;
      font-size: 12px;
      cursor: pointer;
    }
    .structure + .structure {
      margin-left: 5px;
    }
    .structure.active {
      border-color: $color-danger;
      color: $color-primary;
      background: #fff;
    }
  }
  .price {
    display: flex;
    justify-content: center;
  }
  .editCommonTable {
    .figure-edit,
    .sizeList-edit,
    .struct-edit,
    .sizeList-edit {
      position: absolute;
      right: 30px;
      bottom: 0px;
    }
    .merge-slot-class {
      top: 15px;
    }
    ::v-deep {
      .el-table__row {
        .cell {
          padding: 20px 10px;
          .el-table {
            .cell {
              padding: 0 10px;
            }
          }
        }
      }
    }
  }

  .el-tag {
    margin-right: 5px;
    margin-top: 5px;
    span {
      font-size: 12px;
      display: inline-block;
      color: $color-danger;
    }
  }
  ::v-deep .referSizeClass {
    border-right: 1px solid #ebeef5;
  }
}
::v-deep {
  .el-table__row--level-1.bg-transparent {
    > td > .cell {
      padding: 20px 10px;
    }
  }
  .dialog-title {
    font-size: 14px;
    color: #1a1a1a;
    height: 52px;
    display: flex;
    align-items: center;
  }

  .expand-level-2 {
    > .cell {
      padding: 0 88px !important;
    }
  }
}

.new-product-tag {
  background: $green;
  color: #fff;
  padding: 2px;
  border-radius: 2px;
}
</style>
