var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loadingBtn',{staticClass:"filter-item",attrs:{"type":"text","size":"mini"},on:{"click":_vm.onclick}},[_vm._v("查看价格")]),(_vm.isShowDialog)?_c('el-dialog',{attrs:{"visible":_vm.dialogVisible,"custom-class":"view-price-dialog dialog-header-border","width":"840px","top":"8vh","title":"原型价格","append-to-body":""},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticClass:"app-flex-col-container"},[_c('listStateGroup',{attrs:{"list":_vm.list},model:{value:(_vm.curVal),callback:function ($$v) {_vm.curVal=$$v},expression:"curVal"}}),_c('div',{staticClass:"flex-col-content mt20"},[(_vm.newData.option)?_c('customTable',{attrs:{"data":_vm.newData.data,"option":_vm.newData.option},scopedSlots:_vm._u([{key:"menuLeft",fn:function(){return [_vm._v("最新价格：")]},proxy:true},{key:("price" + _vm.levelItemId + "Header"),fn:function(ref){
var column = ref.column;
return [_c('div',{staticClass:"description-header current-level-header",attrs:{"title":"固定档位价格"}},[_vm._v(_vm._s(column.label))])]}},{key:("price" + _vm.levelItemId),fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('span',{staticClass:"current-level"},[_vm._v(_vm._s(_vm.discountFormatter(row, column, row[column.property])))])]}}],null,true)}):_vm._e()],1),_c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.onload),expression:"onload"}],staticClass:"flex-col-content"},[_vm._l((_vm.finalData),function(item,index){return _c('customTable',{key:index + _vm.levelItemId,attrs:{"data":item.data,"option":item.option},scopedSlots:_vm._u([{key:"menuLeft",fn:function(){return [_vm._v(" "+_vm._s(item.startTime)+" 编辑了价格")]},proxy:true},{key:"menuRight",fn:function(){return [_vm._v(_vm._s(item.startTime)+" - "+_vm._s(item.endTime))]},proxy:true},(index === 0)?{key:("price" + _vm.levelItemId + "Header"),fn:function(ref){
var column = ref.column;
return [_c('div',{staticClass:"description-header current-level-header",attrs:{"title":"固定档位价格"}},[_vm._v(_vm._s(column.label))])]}}:null,(index === 0)?{key:("price" + _vm.levelItemId),fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('span',{staticClass:"current-level"},[_vm._v(_vm._s(_vm.discountFormatter(row, column, row[column.property])))])]}}:null],null,true)})}),(_vm.tableLoading)?_c('p',{staticClass:"text-center"},[_vm._v("加载中...")]):(_vm.noMore)?_c('p',{staticClass:"text-center"},[_vm._v("没有更多了")]):_vm._e()],2)],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }