<template>
  <TreeSelect
    :selectedValue.sync="value"
    :options="finalData"
    :defaultExpandLevel="2"
    :normalKeys="{
      id: 'id',
      name: 'name'
    }"
    :placeholder="$attrs.placeholder || '请选择分类'"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import TreeSelect from '@/components/treeSelect'
import { mapGetters } from 'vuex'

export default {
  components: {
    TreeSelect
  },
  props: {
    selectedValue: [Array, Number, String, Object],
    otherIncluded: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: ''
    }
  },
  watch: {
    value(newVal) {
      this.$emit('update:selectedValue', newVal)
    },
    selectedValue: {
      handler(newVal) {
        this.value = newVal
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters({
      data: 'proCategory'
    }),
    finalData() {
      if (!this.otherIncluded) return this.data
      return [...this.data, { children: null, id: 0, value: 0, label: '其他', name: '其他' }]
    }
  },
}
</script>

<style></style>
