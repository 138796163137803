/* eslint-disable */
import { numberToChinese } from '@/utils'
import { validatenull } from '@/components/avue/utils/validate'
import cloneDeep from 'lodash/cloneDeep'

export function handlePriceData(levelCategoryPriceDetail, toJson = true) {
  try {
    let column = [
      {
        label: '尺码',
        prop: 'sizeName'
      }
      // {
      //   label: '最低售价',
      //   prop: 'sizeLimitPrice'
      // }
    ]
    let detail
    if (toJson) detail = JSON.parse(levelCategoryPriceDetail)
    else detail = cloneDeep(levelCategoryPriceDetail)
    let data = []
    let levelCategories = {}
    let sizeNames = {}
    detail.forEach((item) => {
      let {
        levelCategoryItem: { id, minCount, maxCount },
        levelCategoryItem,
        sizeId,
        price
      } = item
      if (id) {
        let priceProp = `price${id}`
        if (!levelCategories[id]) {
          levelCategories[id] = levelCategoryItem
          column.push({
            label: maxCount >= 9999 ? `${minCount}+价格` : `${minCount}-${maxCount}件价格`,
            prop: priceProp,
            placeholder: 0,
            isPrice: true
          })
        }

        if (sizeNames[sizeId]) {
          sizeNames[sizeId][priceProp] = price
        } else {
          item[priceProp] = price
          sizeNames[sizeId] = item
          data.push(item)
        }
      }
    })
    return {
      data,
      column
    }
  } catch (e) {}
}

export function getSizeLevelCategoryPriceList(sizeList) {
  return (sizeList || []).map((size) => {
    let tempObj = {
      id: size.id,
      sizeName: size.sizeName,
      lowerLimitPrice: size.lowerLimitPrice
    }
    tempObj.priceList = (size.sizeLevelCategoryPriceList || []).map((price) => {
      let {
        levelCategoryItem: { id: prop, minCount, maxCount },
        price: value
      } = price
      tempObj[prop] = value
      return {
        prop,
        value,
        label: maxCount >= 9999 ? `${minCount}+` : `${minCount}-${maxCount}件`
      }
    })
    return tempObj
  })
}

export function handleFixedLevelCategoryByLevelItemId(sizeList, levelItemId) {
  let hasFixedLevelCategory = false
  ;(sizeList || []).forEach((size) => {
    size.fixedLevelCategory = (size.sizeLevelCategoryPriceList || []).find((price) => price.levelItemId === levelItemId)
    if (!hasFixedLevelCategory) hasFixedLevelCategory = !!size.fixedLevelCategory
  })
  return hasFixedLevelCategory
}

export function getLevelCategoryList(sizeList) {
  let sizeLevelCategoryPriceList = getSizeLevelCategoryPriceList(sizeList)
  let priceList = sizeLevelCategoryPriceList.find(({ priceList }) => !validatenull(priceList))?.priceList || []
  return priceList.map((price, index) => ({
    label: `${numberToChinese(index + 1)}档：${price.label}`,
    value: price.prop
  }))
}

// 获取固定档位id
// 固定档位回显：无固定档位或档位改变时，显示第一个档位
export function findFixedLevelItemId(levelCategoryList, fixedLevelItemId, valueProp = 'value') {
  if (validatenull(levelCategoryList)) return fixedLevelItemId
  if (!levelCategoryList.some((item) => item[valueProp] === fixedLevelItemId))
    fixedLevelItemId = levelCategoryList[0]?.value
  return fixedLevelItemId
}
