import request from '@/service/request'
import store from '@/store'
import { getXMenuType } from '@/utils/constant'

export function list(data) {
  return request({
    url: '/externaladmin/productService/productCategory/list',
    method: 'post',
    data
  })
}

export async function add(data, menuType = 'btn') {
  // eslint-disable-next-line no-undef
  return refreshDic(
    request({
      headers: getXMenuType(menuType),
      url: '/externaladmin/productService/productCategory/create',
      method: 'post',
      data
    }),
    'proCategory'
  )
}

export function read(data) {
  return request({
    url: `/api/prim_prod/product_category/${data}/`,
    method: 'get',
    data
  })
}

export async function edit(data, menuType = 'btn') {
  // eslint-disable-next-line no-undef
  return refreshDic(
    request({
      headers: getXMenuType(menuType),
      url: '/externaladmin/productService/productCategory/update',
      method: 'post',
      data
    }),
    'proCategory'
  )
}

export async function del(data, menuType = 'btn') {
  // eslint-disable-next-line no-undef
  return refreshDic(
    request({
      headers: getXMenuType(menuType),
      url: '/externaladmin/productService/productCategory/delete',
      method: 'post',
      data
    }),
    'proCategory'
  )
}
