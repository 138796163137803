<template>
  <div>
    <loadingBtn type="text" size="mini" class="filter-item" @click="onclick">查看价格</loadingBtn>
    <el-dialog
      v-if="isShowDialog"
      :visible.sync="dialogVisible"
      custom-class="view-price-dialog dialog-header-border"
      width="840px"
      top="8vh"
      title="原型价格"
      append-to-body
    >
      <div class="app-flex-col-container">
        <listStateGroup v-model="curVal" :list="list"></listStateGroup>
        <div class="flex-col-content mt20">
          <customTable v-if="newData.option" :data="newData.data" :option="newData.option">
            <template #menuLeft>最新价格：</template>

            <template v-slot:[`price${levelItemId}Header`]="{ column }">
              <div class="description-header current-level-header" title="固定档位价格">{{ column.label }}</div>
            </template>
            <template v-slot:[`price${levelItemId}`]="{ row, column }">
              <span class="current-level">{{ discountFormatter(row, column, row[column.property]) }}</span>
            </template>
          </customTable>
        </div>
        <div class="flex-col-content" v-infinite-scroll="onload">
          <customTable
            v-for="(item, index) in finalData"
            :key="index + levelItemId"
            :data="item.data"
            :option="item.option"
          >
            <template #menuLeft> {{ item.startTime }} 编辑了价格</template>
            <template #menuRight>{{ item.startTime }} - {{ item.endTime }}</template>

            <template v-if="index === 0" v-slot:[`price${levelItemId}Header`]="{ column }">
              <div class="description-header current-level-header" title="固定档位价格">{{ column.label }}</div>
            </template>
            <template v-if="index === 0" v-slot:[`price${levelItemId}`]="{ row, column }">
              <span class="current-level">{{ discountFormatter(row, column, row[column.property]) }}</span>
            </template>
          </customTable>
          <p v-if="tableLoading" class="text-center">加载中...</p>
          <p v-else-if="noMore" class="text-center">没有更多了</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import listStateGroup from '@/views/components/listStateGroup'
import customTable from '@/views/components/customTable'
import { avueCrud, dialogFormMixin, getSearchValueMixin } from '@/mixins'
import { getPriceModifyRecord as getList, getPrice } from '@/api/product/productApi'
import { mapGetters } from 'vuex'
import { handlePriceData } from '@/views/product/utils'
import { accMul, flat } from '@/utils'
import { cloneDeep } from 'lodash'

let option = {
  topPage: false,
  page: false,
  menu: false
}
export default {
  mixins: [
    dialogFormMixin,
    avueCrud({
      getList,
      isInit: false,
      isInfiniteScroll: true
    }),
    getSearchValueMixin({
      getInitVal: false
    })
  ],
  components: {
    listStateGroup,
    customTable
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    showFixedLevel: Boolean,
    mainAccountUid: Number | String,
    memberLevelId: Number | String
  },
  data() {
    return {
      finalData: [],
      tablePage: {
        pageIndex: 0,
        pageSize: 10,
        total: 0
      },
      priceTable: [],
      loading: false,
      fixedLevelItemId: 0
    }
  },
  computed: {
    ...mapGetters(['memberLevelList']),
    list() {
      return this.memberLevelList || []
    },
    newData({ finalData }) {
      const data = finalData[0]?.data.map((item, index) => {
        item.sizeLimitPrice = this.data.$sizeList?.[index]?.lowerLimitPrice
        return item
      })
      console.log('Object.assign({}, finalData[0], { data })', cloneDeep(Object.assign({}, finalData[0], { data })))
      return Object.assign({}, finalData[0], { data })
    },
    resetMergeData({ data }) {
      return {
        prototypeId: data.id,
        orderItems: [
          { asc: false, column: 'modify_time' },
          { asc: false, column: 'id' }
        ]
      }
    },
    levelItemId() {
      return this.$attrs.levelItemId || this.fixedLevelItemId || 0
    }
  },
  watch: {
    dialogVisible: {
      async handler(dialogVisible) {
        if (dialogVisible) {
          if (this.showFixedLevel) {
            let { hasFixedLevelCategory, fixedLevelCategoryConfigId } = this.data
            if (hasFixedLevelCategory) this.fixedLevelItemId = fixedLevelCategoryConfigId
          }
        }
      }
    },
    list: {
      handler(n) {
        if (n.length) {
          this.curVal = this.memberLevelId || n[0].value
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.$store.dispatch('GetDicRetry', 'memberLevelList')
  },
  methods: {
    async onclick() {
      this.dialogVisible = true
      // await this.onload()
    },
    afterInit(res) {
      let detail = res?.detail || []
      let { finalData } = this

      let lastItem = finalData[finalData.length - 1]

      detail.forEach((item, index) => {
        let { column, data } = handlePriceData(item.levelCategoryPriceDetail)
        column.forEach((item) => {
          if (item.isPrice) item.formatter = this.discountFormatter
        })
        finalData.push({
          data,
          option: {
            ...option,
            column
          },
          startTime: item.modifyTime,
          endTime: index === 0 ? (finalData.length === 0 ? '至今' : lastItem.startTime) : detail[index - 1].modifyTime
        })
      })
    },
    discountFormatter(row, column, value) {
      return accMul(value, this.curItem.priceDiscount)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep.view-price-dialog {
  .el-dialog__body {
    padding: 10px 20px 35px;
    max-height: 700px;
    overflow: hidden;
  }
  .app-flex-col-container {
    max-height: 655px;
  }
  .flex-col-content {
    padding: 0;
  }
  .crud-container {
    width: 100%;
    .menu-left {
      margin: 18px 0 8px;
    }
    .el-table {
      margin-bottom: 0;
    }
  }
}

::v-deep .el-table th > .cell {
  overflow: initial;
}
.description-header {
  &::after {
    content: attr(title);
    position: absolute;
    bottom: 0;
    left: 10px;
    line-height: 1;
    transform: translateY(100%);
    font-size: 12px;
  }
}
.current-level {
  color: $color-title;
  font-weight: 700;
}
.flex-col-content {
  > .crud-container:first-child {
    .current-level-header::after,
    .current-level {
      color: $color-primary;
    }
  }
}
</style>
