<template>
  <el-checkbox
    v-if="showDom"
    class="checkbox-large"
    :value="checked"
    :indeterminate="indeterminate"
    :disabled="!tableData.length"
    v-bind="$attrs"
    v-on="$listeners"
    @change="changeHandler"
  >
    <slot>
      {{ content }}
    </slot>
  </el-checkbox>
</template>

<script>
import { cloneDeep, flatten, uniq } from 'lodash'

export default {
  props: {
    showDom: {
      type: Boolean,
      default: true
    },

    content: {
      type: String,
      default: '全选'
    },

    tableData: {
      type: Array,
      default: () => []
    },

    selectionData: {
      type: Array,
      default: () => []
    },

    getAllDataApi: {
      type: Function
    },

    page: Number,

    size: Number,

    total: Number,

    //是否是后端原始数据，未进行拆分的数据。
    isOriginData: {
      type: Boolean,
      default: true
    },

    isPageChange: Boolean
  },

  data() {
    return {
      checked: false,
      allPageSelectedData: {},
      allDelPageSelectedData: {}
    }
  },

  computed: {
    indeterminate({ allPageSelectedData, allDelPageSelectedData, checked, totalPage, keyPage }) {
      const data = flatten(cloneDeep(Object.values(allPageSelectedData)))
      const delData = flatten(cloneDeep(Object.values(allDelPageSelectedData)))
      const len = data.filter(Boolean).length
      const delLen = delData.filter(Boolean).length
      if (!totalPage) return false
      if (checked) {
        if (data.length == len) {
          return false
        }
        if (keyPage == totalPage && delLen == delData.length) {
          this.checked = false
          return false
        }
        return true
      }

      if (!checked) {
        if (keyPage == totalPage && len == data.length && data.length > 0) {
          console.log(6666)
          //所有元素都选中，设置锁，禁止初始化事件。
          this.lockToToggleChecked(true)
          return false
        }
        if (len > 0 && data.length > 0) {
          return true
        }
        return false
      }
    },

    keyPage({ allPageSelectedData }) {
      return Object.keys(allPageSelectedData).length
    },

    totalPage({ total, size }) {
      const { ceil } = Math
      return ceil(total / size)
    },

    mapPosToTableData({ selectionData, tableData }) {
      const tmpArr = []
      const ids = selectionData.map(({ id }) => id)
      tableData.map((item) => {
        const isExist = ids.includes(item.id)
        tmpArr.push(isExist ? item : undefined)
      })
      return tmpArr
    },

    mapDelPosToTableData({ selectionData, tableData }) {
      const tmpArr = []
      const ids = selectionData.map(({ id }) => id)
      tableData.map((item) => {
        const isExist = ids.includes(item.id)
        tmpArr.push(!isExist ? item : undefined)
      })
      return tmpArr
    },

    curPageSelectedData({ allPageSelectedData, page }) {
      return allPageSelectedData[page] || []
    }
  },

  watch: {
    selectionData() {
      if (this.isPageChange) return
      this.updateAllPageSelectedData()
    },

    size() {
      this.resetData()
    },

    checked(newVal) {
      if (this.checkedLock) return
      this.initData(newVal)
    }
  },

  created() {
    this.resetParentFuncs()
  },

  methods: {
    changeHandler(checked) {
      if (this.indeterminate && !checked) {
        this.checked = true
      } else {
        this.checked = checked
      }
      if (this.checked && !this.checkedLock) {
        this.initData(this.checked)
      }
    },

    lockToToggleChecked(checked) {
      this.checkedLock = true
      this.checked = checked
      this.$nextTick(() => {
        this.checkedLock = false
      })
    },

    resetParentFuncs() {
      this.parentInitCallBack = (this.$parent.initCallBack || this.noop).bind(this.$parent)
      this.parentSearchChange = (this.$parent.searchChange || this.noop).bind(this.$parent)
      this.$parent.initCallBack = this.initCallBack
      this.$parent.searchChange = this.searchChange
    },

    //空方法
    noop() {},

    searchChange() {
      this.parentSearchChange()
      this.resetData(false)
    },

    resetData() {
      this.checked = false
      this.initData(this.checked)
    },

    initData(checked) {
      this.allPageSelectedData = {}
      this.allDelPageSelectedData = {}
      this.$nextTick(() => {
        if (!checked) {
          this.updateSelectionData([])
          this.setChecked(this.tableData, false)
          return
        }
        if (checked) {
          this.updateSelectionData(this.tableData)
          this.updateAllPageSelectedData()
          this.setChecked(this.tableData)
        }
      })
    },

    updateSelectionData(data) {
      this.$emit('update:selectionData', data.filter(Boolean))
    },

    initCallBack(data) {
      this.parentInitCallBack(data)
      this.$emit('update:isPageChange', false)
      setTimeout(() => {
        if (this.checked) {
          this.updateSelectionData(this.getPushData())
          this.$nextTick(() => {
            this.updateAllPageSelectedData()
          })
        }
        this.$nextTick(() => {
          this.setChecked(this.curPageSelectedData)
        })
      }, 0)
    },

    getPushData() {
      const { tableData, curPageSelectedData } = this
      return curPageSelectedData.length ? curPageSelectedData : tableData
    },

    updateAllPageSelectedData() {
      const { mapPosToTableData, allPageSelectedData, allDelPageSelectedData, mapDelPosToTableData, page } = this
      this.$set(allPageSelectedData, `${page}`, mapPosToTableData)
      this.$set(allDelPageSelectedData, `${page}`, mapDelPosToTableData)
    },

    /**
     * @description: 表格打钩
     * @param {Array} data
     * @return {*}
     */
    setChecked(data, checked = true) {
      //设置延迟，在vue渲染完成之后调用。
      this.$nextTick(() => {
        const { table } = this.$parent.$refs
        table.toggleSelection(this.selectedTableData(data), checked)
      })
    },

    selectedTableData(data) {
      return this.tableData.filter((item) => {
        return data.find(({ id } = {}) => {
          return this.isShaking(id, item)
        })
      })
    },

    isShaking(id, item) {
      return id == item.id
    },

    async getAllSelectedData(data = {}) {
      const { getAllDataApi, getDelData, getSyncSelectedData } = this
      if (!getAllDataApi) {
        return [false, getSyncSelectedData()]
      }
      const { code, detail } = await getAllDataApi(data)
      if ($SUC({ code })) {
        const data = (detail || []).filter((item) => {
          return !getDelData().find(({ id }) => item.id == id)
        })
        return [false, data]
      }
      return [true, []]
    },

    getSyncSelectedData() {
      return flatten(cloneDeep(Object.values(this.allPageSelectedData))).filter(Boolean)
    },

    getDelData() {
      return flatten(cloneDeep(Object.values(this.allDelPageSelectedData))).filter(Boolean)
    },

    /**
     * @description: 根据传入的初始值和权限状态返回对应的选择数据
     * @param {Array} cacheSyncData 同步数据
     * @return {*}
     */
    async getSmartAllData(cacheSyncData = []) {
      const { checked, isOriginData, getDelData, getAllSelectedData, getSyncSelectedData } = this
      const syncSelectedData = getSyncSelectedData()
      //当存在缓存初始数据，所有的数据都来自于缓存数据
      if (cacheSyncData.length) {
        return [false, cacheSyncData.filter(({ id: cacheItemId }) => !getDelData().some(({ id }) => id == cacheItemId))]
      }
      if (checked) {
        return await getAllSelectedData()
      }
      if (!isOriginData && syncSelectedData.length) {
        return await getAllSelectedData({
          idList: uniq(getSyncSelectedData().map(({ id }) => id))
        })
      }
      return [false, syncSelectedData]
    }
  }
}
</script>

<style lang="scss" scoped>
.el-checkbox {
  width: 100px;
}
</style>