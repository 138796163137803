import request from '@/service/request'

//主子账号模块，根据登录账号获取子账号列表
export function getSubAccount(data = {}) {
  return request({
    url: '/externaladmin/authService/user/childrenList',
    method: 'post',
    data
  })
}

//导出分销商信息
export function exportMerchantData(data) {
  return request({
    url: '/externaladmin/reportService/misImageData/exportAll',
    method: 'post',
    responseType: 'blob',
    data
  })
}

//导出图片信息
export function exportImageData(data) {
  return request({
    url: '/externaladmin/reportService/misImageDailyData/exportAll',
    method: 'post',
    responseType: 'blob',
    data
  })
}
