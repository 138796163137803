import { productLabelList } from '@/utils/constant/productConst'

//上架
export const waitShelvesField = [
  {
    label: '原型分类',
    prop: 'category',
    name: 'category',
    colNum: 5,
    slotName: 'categorySlot',
    placeholder: '请选择原型分类'
  },
  {
    label: '适用人群',
    prop: 'sex',
    name: 'sex',
    colNum: 5,
    placeholder: '请选择适用人群',
    slotName: 'sexSlot'
  },
  {
    label: '适用季节',
    prop: 'season',
    name: 'season',
    colNum: 5,
    placeholder: '请选择适用季节',
    slotName: 'seasonSlot'
  },
  {
    label: '添加日期',
    prop: 'create_time',
    name: 'create_time',
    placeholder: '请选择添加日期',
    colNum: 7,
    slotName: 'timeSlot'
  },
  /*{
    label: '海关编号',
    prop: 'customs_code',
    name: 'customs_code',
    placeholder: '输入原型海关HS编号',
    colNum: 5
  },*/
  {
    label: '原型名称',
    prop: 'name',
    name: 'name',
    colNum: 5,
    placeholder: '请输入原型名称'
  },
  {
    label: '款式',
    prop: 'struct_name',
    name: 'struct_name',
    colNum: 5,
    placeholder: '请输入款式'
  },
  {
    label: '价格',
    prop: 'price',
    name: 'price',
    isRange: true,
    colNum: 7,
    placeholder: '请输入款式'
  },
  {
    label: '',
    prop: 'done',
    name: 'done',
    colNum: 1,
    slotName: 'doneSlot'
  }
]

//上架
export const shelvesField = [
  {
    label: '原型分类',
    prop: 'platformCategoryId',
    name: 'category',
    colNum: 5,
    slotName: 'categorySlot',
    placeholder: '请选择原型分类'
  },
  {
    label: '适用人群',
    prop: 'sex',
    name: 'sex',
    colNum: 5,
    placeholder: '请选择适用人群',
    slotName: 'sexSlot'
  },
  {
    label: '适用季节',
    prop: 'season',
    name: 'season',
    colNum: 5,
    placeholder: '请选择适用季节',
    slotName: 'seasonSlot'
  },
  {
    label: '原型状态',
    prop: 'status',
    name: 'status',
    colNum: 5,
    placeholder: '请选择原型状态',
    slotName: 'statusSlot'
  },
  {
    label: '创建日期',
    prop: 'create_time',
    name: 'create_time',
    colNum: 7,
    slotName: 'timeSlot'
  },
  {
    label: '原型名称',
    prop: 'chineseName',
    name: 'chineseName',
    colNum: 5,
    placeholder: '请输入原型名称'
  },
  {
    label: '款式',
    prop: 'styleName',
    name: 'styleName',
    colNum: 5,
    placeholder: '请输入款式'
  },
  {
    label: '标签',
    prop: 'isQueryLabelType',
    name: 'isQueryLabelType',
    colNum: 5,
    dicData: productLabelList,
    type: 'select',
    placeholder: '请选择标签'
  },
  {
    label: '价格',
    prop: 'LevelPrice',
    name: 'LevelPrice',
    isRange: true,
    colNum: 8
  },
  {
    label: '',
    prop: 'done',
    name: 'done',
    colNum: 1,
    slotName: 'doneSlot'
  }
]

// 原型基础信息
export const baseInfoField = [
  {
    label: '原型分类',
    prop: 'productCategoryId',
    name: 'productCategoryId',
    colNum: 5,
    slotName: 'categorySlot',
    placeholder: '请选择原型分类'
  },
  {
    label: '适用人群',
    prop: 'sex',
    name: 'sex',
    colNum: 5,
    placeholder: '请选择适用人群',
    slotName: 'sexSlot'
  },
  {
    label: '适用季节',
    prop: 'season',
    name: 'season',
    colNum: 5,
    placeholder: '请选择适用季节',
    slotName: 'seasonSlot'
  },
  {
    label: '创建日期',
    prop: 'create_time',
    name: 'create_time',
    placeholder: '请选择创建日期',
    colNum: 7,
    slotName: 'timeSlot'
  },
  /*{
    label: '海关编号',
    prop: 'customs_code',
    name: 'customs_code',
    placeholder: '输入原型海关HS编号',
    colNum: 5
  },*/
  {
    label: '原型名称',
    prop: 'name',
    name: 'name',
    colNum: 5,
    placeholder: '请输入原型名称'
  },
  {
    label: '款式',
    prop: 'structName',
    name: 'structName',
    colNum: 5,
    placeholder: '请输入款式'
  },
  {
    label: '原型状态',
    prop: 'status',
    name: 'status',
    colNum: 5,
    placeholder: '请选择原型状态',
    slotName: 'shelvesSlot'
  },
  {
    label: '负责人',
    prop: 'ownerId',
    name: 'ownerId',
    colNum: 5,
    placeholder: '请选择负责人',
    slotName: 'ownerIdSlot'
  },
  {
    label: '分配状态',
    prop: 'isDistribute',
    name: 'isDistribute',
    colNum: 5,
    placeholder: '请选择',
    slotName: 'isDistributeSlot'
  },
  {
    label: '',
    prop: 'done',
    name: 'done',
    colNum: 1,
    slotName: 'doneSlot'
  }
]

// child 原型基础信息
export const baseInfoChildField = [
  {
    label: '原型分类',
    prop: 'productCategoryId',
    name: 'productCategoryId',
    colNum: 5,
    slotName: 'categorySlot',
    placeholder: '请选择原型分类'
  },
  {
    label: '适用人群',
    prop: 'sex',
    name: 'sex',
    colNum: 5,
    placeholder: '请选择适用人群',
    slotName: 'sexSlot'
  },
  {
    label: '适用季节',
    prop: 'season',
    name: 'season',
    colNum: 5,
    placeholder: '请选择适用季节',
    slotName: 'seasonSlot'
  },
  {
    label: '创建日期',
    prop: 'create_time',
    name: 'create_time',
    placeholder: '请选择创建日期',
    colNum: 7,
    slotName: 'timeSlot'
  },
  {
    label: '原型名称',
    prop: 'name',
    name: 'name',
    colNum: 5,
    placeholder: '请输入原型名称'
  },
  {
    label: '款式',
    prop: 'structName',
    name: 'structName',
    colNum: 5,
    placeholder: '请输入款式'
  },
  {
    label: '原型状态',
    prop: 'status',
    name: 'status',
    colNum: 5,
    placeholder: '请选择原型状态',
    slotName: 'shelvesSlot'
  },
  {
    prop: 'isTort',
    label: '是否侵权',
    minWidth: '150',
    colNum: 5,
    slotName: 'tortSlot'
  },
  {
    label: '',
    prop: 'done',
    name: 'done',
    colNum: 1,
    slotName: 'doneSlot'
  }
]

// 价格管理
export const priceField = [
  {
    label: '原型分类',
    prop: 'platformCategoryId',
    name: 'platformCategoryId',
    colNum: 5,
    slotName: 'categorySlot',
    placeholder: '请选择原型分类'
  },
  {
    label: '原型名称',
    prop: 'chineseName',
    name: 'chineseName',
    colNum: 5,
    placeholder: '请输入原型名称'
  },
  {
    label: '款式',
    prop: 'styleName',
    name: 'styleName',
    colNum: 5,
    placeholder: '请输入款式'
  },
  {
    label: '原型状态',
    prop: 'status',
    name: 'status',
    colNum: 5,
    placeholder: '请选择原型状态',
    slotName: 'shelvesSlot'
  },
  // {
  //   label: '负责人',
  //   prop: 'ownerId',
  //   name: 'manager',
  //   value: 'manager',
  //   colNum: 5,
  //   placeholder: '请选择负责人',
  //   slotName: 'managerSlot'
  // },
  {
    label: '',
    prop: 'done',
    name: 'done',
    colNum: 2,
    slotName: 'doneSlot'
  }
]

//下架
export const unShelvesField = [
  {
    label: '原型分类',
    prop: 'category',
    name: 'category',
    colNum: 5,
    slotName: 'categorySlot',
    placeholder: '请选择原型分类'
  },
  {
    label: '适用人群',
    prop: 'sex',
    name: 'sex',
    colNum: 5,
    placeholder: '请选择适用人群',
    slotName: 'sexSlot'
  },
  {
    label: '适用季节',
    prop: 'season',
    name: 'season',
    colNum: 5,
    placeholder: '请选择适用季节',
    slotName: 'seasonSlot'
  },
  {
    label: '下架日期',
    prop: 'off_time',
    name: 'off_time',
    placeholder: '请选择下架日期',
    colNum: 7,
    slotName: 'timeSlot'
  },
  /*{
    label: '海关编号',
    prop: 'customs_code',
    name: 'customs_code',
    placeholder: '输入原型海关HS编号',
    colNum: 5
  },*/
  {
    label: '原型名称',
    prop: 'name',
    name: 'name',
    colNum: 5,
    placeholder: '请输入原型名称'
  },
  {
    label: '款式',
    prop: 'struct_name',
    name: 'struct_name',
    colNum: 5,
    placeholder: '请输入款式'
  },
  {
    label: '价格',
    prop: 'price',
    name: 'price',
    isRange: true,
    colNum: 7,
    placeholder: '请输入款式'
  },
  {
    label: '',
    prop: 'done',
    name: 'done',
    colNum: 1,
    slotName: 'doneSlot'
  }
]

//刀版图管理
export const knifeMangeField = [
  {
    label: '原型分类',
    prop: 'productCategoryId',
    name: 'category',
    colNum: 5,
    slotName: 'categorySlot',
    placeholder: '请选择原型分类'
  },
  {
    label: '适用人群',
    prop: 'sex',
    name: 'sex',
    colNum: 5,
    placeholder: '请选择适用人群',
    slotName: 'sexSlot'
  },
  {
    label: '适用季节',
    prop: 'season',
    name: 'season',
    colNum: 5,
    placeholder: '请选择适用季节',
    slotName: 'seasonSlot'
  },
  {
    label: '添加时间',
    prop: 'create_time',
    name: 'create_time',
    placeholder: '请选择添加日期',
    colNum: 8,
    slotName: 'timeSlot'
  },
  {
    label: '原型名称',
    prop: 'name',
    name: 'name',
    colNum: 5,
    placeholder: '请输入原型名称'
  },
  {
    label: '款式',
    prop: 'structName',
    name: 'structName',
    colNum: 5,
    placeholder: '请输入款式'
  },
  {
    label: 'psd状态',
    prop: 'isUploadPsd',
    name: 'isUploadPsd',
    colNum: 5,
    slotName: 'size_psdSlot',
    placeholder: '请输入psd上传状态'
  },
  {
    label: '原型状态',
    prop: 'status',
    name: 'status',
    colNum: 5,
    placeholder: '请选择原型状态',
    slotName: 'shelvesSlot'
  },
  {
    label: '负责人',
    prop: 'ownerId',
    name: 'manager',
    value: 'manager',
    colNum: 5,
    placeholder: '请选择负责人',
    slotName: 'managerSlot'
  },
  {
    label: '',
    prop: 'done',
    name: 'done',
    colNum: 1,
    slotName: 'doneSlot'
  }
]

//参考尺码管理
export const sizeMangeField = [
  {
    label: '原型分类',
    prop: 'category',
    name: 'category',
    colNum: 5,
    slotName: 'categorySlot',
    placeholder: '请选择原型分类'
  },
  {
    label: '添加时间',
    prop: 'create_time',
    name: 'create_time',
    placeholder: '请选择添加日期',
    colNum: 8,
    slotName: 'timeSlot'
  },
  /*{
    label: '海关编号',
    prop: 'customs_code',
    name: 'customs_code',
    placeholder: '输入原型海关HS编号',
    colNum: 5
  },*/
  {
    label: '原型名称',
    prop: 'name',
    name: 'name',
    colNum: 5,
    placeholder: '请输入原型名称'
  },
  {
    label: '款式',
    prop: 'struct_name',
    name: 'struct_name',
    colNum: 5,
    placeholder: '请输入款式'
  },
  {
    label: 'psd状态',
    prop: 'size_psd',
    name: 'size_psd',
    colNum: 5,
    slotName: 'size_psdSlot',
    placeholder: '请输入psd上传状态'
  },
  {
    label: '',
    prop: 'done',
    name: 'done',
    colNum: 1,
    slotName: 'doneSlot'
  }
]

//效果图管理
export const proShowMangeField = [
  {
    label: '原型分类',
    prop: 'productCategoryId',
    name: 'productCategoryId',
    colNum: 5,
    slotName: 'categorySlot',
    placeholder: '请选择原型分类'
  },
  {
    label: '原型名称',
    prop: 'name',
    name: 'name',
    colNum: 5,
    placeholder: '请输入原型名称'
  },
  {
    label: '款式',
    prop: 'structName',
    name: 'structName',
    colNum: 5,
    placeholder: '请输入款式'
  },
  {
    label: '原型状态',
    prop: 'status',
    name: 'status',
    colNum: 5,
    placeholder: '请选择原型状态',
    slotName: 'shelvesSlot'
  },
  {
    label: '负责人',
    prop: 'ownerId',
    name: 'manager',
    value: 'manager',
    colNum: 5,
    placeholder: '请选择负责人',
    slotName: 'managerSlot'
  },
  {
    label: '',
    prop: 'done',
    name: 'done',
    colNum: 1,
    slotName: 'doneSlot'
  }
]
