<template>
  <span>
    <el-button icon="el-icon-edit-outline" type="text" size="mini" class="filter-item" @click="to">
      编辑款式
    </el-button>
    <StructForm
      ref="form"
      v-if="dialogVisible"
      :dialogVisible.sync="dialogVisible"
      :sup_this="sup_this"
      :data="cData"
      title="编辑款式"
    />
  </span>
</template>
<script>
import StructForm from './structForm'
import cloneDeep from 'lodash/cloneDeep'
import { STRUCR_ITEM, CUSTOM, SHELVES } from '@/utils/constant'
import { createRandomNum } from '@/utils'

export default {
  components: { StructForm },
  props: {
    sup_this: {
      type: Object,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialogVisible: false,
      cData: []
    }
  },
  methods: {
    to() {
      const data = cloneDeep(this.data)
      if (!data.styleList.length) {
        data.styleList.push({
          ...STRUCR_ITEM,
          id: `${CUSTOM}-${createRandomNum()}`
        })
      } else {
        data?.styleList.forEach(item => {
          item?.styleDisplayImageList.forEach(img => {
            this.$set(img, 'path', img.displayImagePath)
            // this.$set(img, 'path', encodeURI(img.displayImagePath))
          })
        })
      }
      this.cData = data
      this.dialogVisible = true
      this.$nextTick(() => {
        const _this = this.$refs.form
        _this.activeName = data.styleList[0].id.toString()
        _this.oStructs = cloneDeep(this.data.styleList || []).filter(({ status }) => SHELVES != status)
      })
    }
  }
}
</script>
